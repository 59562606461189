import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Footer = ({ className }) => (
  <footer className={className}>
    <Link to="/start" className="nav-home">
      <strong>
        Ecology <span className="smallcaps">of an</span> Exhibition
      </strong>
    </Link>
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
}

export default Footer
