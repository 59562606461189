if (typeof window !== 'undefined') {
  var timer = null

  function goAway() {
    clearTimeout(timer)
    timer = setTimeout(function() {
      window.location.href = 'http://youthful-payne-9abf3e.netlify.com/'
    }, 60000)
  }

  window.addEventListener('click', goAway, true)
  window.addEventListener('touchstart', goAway, true)

  goAway()
}
