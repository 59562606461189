import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Footer from './footer'
import './layout.css'
import './timer.js'

const Layout = ({ title, children, data }) => (
  <StaticQuery
    query={graphql`
      query HeadTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content:
                "Investigation of the critical planning decisions and their corresponding environmental implications for the Nature's Nation exhibition at Princeton University Art Museum.",
            },
          ]}
        >
          <html lang="en" />
          {/* <link rel="stylesheet" href="https://use.typekit.net/wvg0bbi.css" /> */}
          <link rel="stylesheet" href="https://use.typekit.net/keo7ant.css" />
          <script src="https://identity.netlify.com/v1/netlify-identity-widget.js" />
        </Helmet>
        <main className="wall">
          {title}
          {children}
        </main>
        <Footer />
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
